import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

// Augment the palette to include an ochre color

// Update the Button's color options to include an ochre option

const theme = createTheme({
  palette: {
    gcpGreen: {
      main: '#85c23d',
      light: '#9ef538',
      dark: '#2E7D32',
      contrastText: '#ffffff',
    },
  },
});

export default function SubmitButton(props) {
  return (
    <ThemeProvider theme={theme}>
        <Button variant={props.variant} 
        onClick={props.onClick} color="gcpGreen">
          Submit
        </Button>
    </ThemeProvider>
  );
}
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ReferralForm from '../components/ReferralForm';
import BuildPackage from '../components/BuildCenterPackage';
import axios from "axios";
import BuildCenterPackage from '../components/BuildCenterPackage';




  function newCenterReferral() {

    return(
    <Container maxWidth="xl">
    <>
    <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          
          {/* <img se></img> */}
          <BuildCenterPackage />


          
        </Grid>
    </Grid>

    </>
    </Container>
    )
  };

  export default newCenterReferral;

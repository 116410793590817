import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ReferralForm from '../components/ReferralForm';

// CSS 





function NewReferral() {





  return (
    <Container maxWidth="xl">
    <>
    <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          

          <ReferralForm />


          
        </Grid>
    </Grid>

    </>
    </Container>
  );
}

export default NewReferral;

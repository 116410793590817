import React, { useState } from 'react'
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Grid, Select, Input, Button, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';




function UpdateInfo() {

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    });

    const referralredirect = localStorage.getItem("referralStart");

    if (referralredirect === "true") {
        window.location.replace("/submit-referral");
    }

    function onButtonClickHandler() {
        window.alert('Password changed')
    };

    let history = useHistory();

    function handleSubmit(e) {
        e.preventDefault();
        alert('A name was submitted: ' + fName);
        const updatedUserInfo = {
            Firstname: fName,
            Lastname: LName,
            Email: Email,
            Phonenumber: Phone,
            Notification: Note,
            Relationship: Relate,
        }
        axios.put(`${process.env.REACT_APP_API}auth/updateUser`, updatedUserInfo, {
            headers: {
                accessToken: localStorage.getItem("accessToken"),
            },
        }
        ).then(async (response) => {
            if (response.status == 200) {
                history.push("/AccountManage");
            } else {
                alert(response.data.error);
            }
        });
    }

    const [fName, setFName] = useState();
    const [LName, setLName] = useState();
    const [Email, setEmail] = useState();
    const [Phone, setPhone] = useState();
    const [Note, setNote] = useState();
    const [Relate, setRelate] = useState();

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );


    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");


    const changePassword = () => {
        axios.put(`${process.env.REACT_APP_API}auth/`,
            {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
            {
                headers: {
                    accessToken: localStorage.getItem("accessToken"),
                },
            }
        )
            .then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                }
            });
    };


    return (
        <div class="loginContainer">
            <div>
                <h1>Change Your Password</h1>
                <input
                    type="text"
                    placeholder="Old Password..."
                    onChange={(event) => {
                        setOldPassword(event.target.value);
                    }}
                />
                <input
                    type="text"
                    placeholder="New Password..."
                    onChange={(event) => {
                        setNewPassword(event.target.value);
                    }}
                />
                <button onClick={() => {
                    changePassword()
                    onButtonClickHandler()
                }}> Save Changes</button>

                <ColoredLine color="Gray" />
            </div>

            <div>
                <form onSubmit={e => { handleSubmit(e) }}>
                    <input
                        type="text"
                        placeholder="First Name..."
                        onChange={e => setFName(e.target.value)}
                    />

                    <input
                        type="text"
                        placeholder="Last Name..."
                        onChange={e => setLName(e.target.value)}
                    />

                    <input
                        type="text"
                        placeholder="Email..."
                        onChange={e => setEmail(e.target.value)}
                    />

                    <input
                        type="text"
                        placeholder="Phonenumber..."
                        onChange={e => setPhone(e.target.value)}
                    />

                    <input
                        type="text"
                        placeholder="Notification..."
                        onChange={e => setNote(e.target.value)}
                    />

                    <input
                        type="text"
                        placeholder="Relationship..."
                        onChange={e => setRelate(e.target.value)}
                    />

                    <div>
                        <input type="submit" value="Save Changes" />
                    </div>

                </form>

                {/* <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                        <FormControl>
                            <form>
                                <Input
                                    type="text"
                                    placeholder="Old Password..."
                                    onChange={(event) => {
                                        setOldPassword(event.target.value);
                                    }}
                                />
                                <Input
                                    type="text"
                                    placeholder="New Password..."
                                    onChange={(event) => {
                                        setNewPassword(event.target.value);
                                    }}
                                />
                                <Button 
                                    variant='contained'
                                    color='success'
                                    onClick={() => {
                                    changePassword()
                                    onButtonClickHandler()
                                }}> Save Changes</Button>
                            </form>
                        </FormControl>
                        
                    </Grid>
                </Grid>

            </div>

            <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
            <FormControl>
                            <form onSubmit={e => { handleSubmit(e) }}>
                                <Input
                                    type="text"
                                    placeholder="First Name..."
                                    onChange={e => setFName(e.target.value)} />
                                <CssTextField
                                    type="text"
                                    placeholder="Last Name..."
                                    onChange={e => setLName(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Email..."
                                    onChange={e => setEmail(e.target.value)}
                                />

                                <Input
                                    type="text"
                                    placeholder="Phonenumber..."
                                    onChange={e => setPhone(e.target.value)}
                                />

                                <Input
                                    type="text"
                                    placeholder="Notification..."
                                    onChange={e => setNote(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Relationship..."
                                    onChange={e => setRelate(e.target.value)}
                                />
                                <Button variant='contained' color='success'>Save Changes</Button>
                            </form>
                            </FormControl> */}
            {/* </Grid>
            </Grid> */}

        </div>
        </div>


    );
}
export default UpdateInfo;
import React from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import Button from '@mui/material/Button';

function AccountManage() {
  let history = useHistory();
  const [userData, setUserData,] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [agency, setAgency] = useState("");
  const [agency_zipcode, setAgency_zipcode] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [nearest_location, setNearest_location] = useState("");
  const [notification, setnotification] = useState("");
  const { authState } = useContext(AuthContext);
  const id  = localStorage.id;
  
  const ele = document.getElementById('gcp-splash')
const loading = localStorage.getItem("loadingScreen");
const referralredirect = localStorage.getItem("referralStart");



if(loading === "true"){
  
  ele.classList.add('available');
        
  setTimeout(() => {
    ele.classList.add('gone');
    
  }, 6000)

  
  
}

if(referralredirect === "true"){
 
  setTimeout(() => {
    window.location.replace("/submit-referral");
    
  }, 5000)
}



  useEffect(() => {
    console.log(authState);
    console.log(localStorage);
    

    
   


    axios.get(`${process.env.REACT_APP_API}auth/basicinfo/?id=${id}`).then((response) => {
      setUserData(response.data);
      localStorage.setItem("username", response.data.username);
        localStorage.setItem("id", response.data.id);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("first_name", response.data.first_name);
        localStorage.setItem("last_name", response.data.last_name);
        localStorage.setItem("agency", response.data.agency);
        localStorage.setItem("agency_zipcode", response.data.agency_zipcode);
        localStorage.setItem("phone_number", response.data.phone_number);
        localStorage.setItem("phone_provider", response.data.phone_provider);
        localStorage.setItem("nearest_location", response.data.nearest_location);
        localStorage.setItem("notification", response.data.notification);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("self_notes", response.data.self_notes);
        localStorage.setItem("assigned_referrals", response.data.num_assigned);
        localStorage.setItem("held_referrals", response.data.num_held);
        localStorage.setItem("completed_referrals", response.data.num_completed);
        
        setTimeout(() => {
          localStorage.setItem("loadingScreen", false);
          
        }, 7001)
    });
  }, []);
    
    
      
              

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={2} />
      <Grid item xs={10}>
      {/* <div className="loginContainer"> */}
      <div className="basicInfo">
        {" "}
        <h3> Username: {userData.username} </h3>
        <h3> First Name: {userData.first_name} </h3>
        <h3> Last Name: {userData.last_name} </h3>
        <h3> Email: {userData.email} </h3>
        <h3> Phone Number: {userData.phone_number} </h3>
        <h3> Notification Type: {userData.notification} </h3>
        <h3> Agency: {userData.agency} </h3>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              history.push("/updateInfo");
            }}
          >
            {" "}
            Change My Account Information
          </Button>
        

      </div>
    {/* </div> */}
    </Grid>
    </Grid>
    </>

  );
}

export default AccountManage;

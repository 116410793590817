import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import SubmitButton from './SubmitButton';
import logo from '../images/email-logo.png';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderitems: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  });
  
  const itemsOptions = [
    'Uniforms',
    'Career Wear',
    'Hygiene',
    'Infant Clothing',
    'Shoes',
    'Casual'
];
  
  const CssSelect = styled(Select)({
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderitems: 'black',
    },
    '& .MuiInputBase-input': {
      borderColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    MenuProps: {
        disableScrollLock: true
    }
};

const BuildCenterPackage =  () => {
    const theme = useTheme();

    // Set Up Error States
    const ReferralFormError = localStorage.getItem("ReferralFormError");
    const [initialErrorState, setInitialError] = React.useState(false);
    const [homelessErrorState, setHomelessError] = React.useState(false);
    const [visitErrorState, setvisitError] = React.useState(false);
    const [itemsErrorState, setItemsError] = React.useState(false);
    const [visitDateErrorState, setVisitDateErrorState] = React.useState(false);



    // Set up variables for user input
    const [visitorName, setvisitorName] = useState('');
    const [homeless, setHomeless] = useState('');
    const [visit, setVisit] = useState('');
    const [items, setItems] = useState([]);
    const [visitDate, setVisitDate] = useState(new Date());
    const [numFamilyDemographics, setNumFamilyDemographics] = useState(1);
    const [familyDemographics, setFamilyDemographics] = useState([{ gender: '', ethnicity: '', age: '' }]);
    // const [familyDemographics, setFamilyDemographics] = useState('');
    
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Test Message')
    const [alertSeverity, setAlertSeverity] = useState('info')
    
    // Set Variables with event
    const handleSetHomeless = (event) => {
    setHomeless(event.target.value);
    };

    const handleSetVisit = (event) => {
        setVisit(event.target.value);
    };

    const handleSetvisitorName = (event) => {
        setvisitorName(event.target.value);
    };
    
    const handleSubmit = (event) => {
        let form_data = {
            'center': 'Emmett Reed Hope Center - Test',
            // 'visit_date': visit_date, 
            'visitor_fullname': visitorName, 
            'visit_type': visit,
            'living_status': homeless,
            'items_received': items.toString(),
            'cnt_family_members': numFamilyDemographics,
            'family_demographics': familyDemographics,
            referrer: 'referrer',
                    }      
        // console.log(form_data)
        let validation = validateForm()
        if (validation.code == 0){
          submit_data(form_data);
        } else {
          setAlertMessage(validation.message);
          setAlertSeverity('error')
          setAlertOpen(true);
        }

    }

    const submit_data = (referral) => {
    axios.post(`${process.env.REACT_APP_API}centerreferrals/`, referral
    ).then(async (response) => {
      if (response.status == 200) {

          console.log(response);
          console.log(response.data.code)
          if (response.data.code == 0){
            setAlertMessage(response.data.message);
            setAlertSeverity('success')
            setAlertOpen(true);
            clearForm();
          }
          // console.log(setAlertMessage, setAlertSeverity, setAlertOpen)
          return response;
      } else {
        alert(response.data.error);
      }
    })
  }

  const validateForm = () => {
      let formElements = [visitorName, visit, homeless];
      let errorCode = 0;
      let familyCode = 0;
      let message = '';
      for (let i = 0 ; i < formElements.length; i++ ){
          if (formElements[i] == '' ) {
            errorCode += 1
          }

      }
      if (errorCode > 0) {
        message += 'Complete the first row. '
      }

      if (items == []){
        errorCode += 1
        message += 'Select items. '}
      

      for (let i = 0; i < familyDemographics.length; i++){
        Object.values(familyDemographics[i]).forEach(value => {
          if (value == "") {
            familyCode += 1
          }
      })
      }
      if (familyCode > 0){
        message += "Fill out family demographics. "
      }
      errorCode = errorCode + familyCode
     ;
      return {code: errorCode, message: message}
  };

  const clearForm = () => {
    setvisitorName('')
    setHomeless('');
    setVisit('');
    setItems([]);
    setVisitDate(new Date());
    setNumFamilyDemographics(1);
    setFamilyDemographics([{ gender: '', ethnicity: '', age: '' }]);
    
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setAlertOpen(false);
    };

    const handleSetItems = (event) => {
        const {
            target: { value },
        } = event;
        setItems(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleNumFamilyDemographicssChange = (e) => {
        const newNum = parseInt(e.target.value, 10);
        setNumFamilyDemographics(newNum);
        setFamilyDemographics(Array(newNum).fill({ gender: '', ethnicity: '', age: '' }));
    };
    const handleFamilyDemographicsChange = (index, field, value) => {
        const updatedMembers = [...familyDemographics];
        updatedMembers[index] = { ...updatedMembers[index], [field]: value };
        setFamilyDemographics(updatedMembers);
    };

    function getItems(itemsOptions, itemsName, theme) {
        return {
            fontWeight:
                itemsName.indexOf(itemsOptions) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <div>

    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
      <div
  style={{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    }}>

      <img src={logo} alt='GCP Logo'/>
    </div>
              
      
      <Typography variant='h5' fontWeight={400} style={{ padding: "50px", textAlign: "center" }}>
        Threads of Resilience Boutique
        {(() => {
          if (ReferralFormError === "true") {
            return <FormHelperText style={{ color: "red", textAlign: "center" }}>Oops! You have field errors that will prevent submission of this referral!</FormHelperText>
          } else {
            return <></>
          }
        })()}
      </Typography>
      <form>
        <Stack spacing={3} direction="row" >

        <Stack spacing={3} direction="column" sx={{ width: "33%" }}>
            <CssTextField
              label="Visitor's Name"
              variant="outlined"
              defaultValue=""
              value={visitorName}
              onChange={handleSetvisitorName}
              style={{ width: "100%", paddingBottom: "25px" }}
              onKeyUp={(e) => {
                if (e.key === "Backspace" && visitorName === "") {
                  setInitialError(true);
                } else if (e.key != "Enter") {
                  setInitialError(false);
                }
              }}
            />
            {(() => {
              if (initialErrorState) {
                return <FormHelperText style={{ color: "red", marginTop: -20, paddingBottom: "10px" }}>Visitor's name is required</FormHelperText>
              } else {
                return <></>
              }
            })()}
          </Stack>
          <Stack spacing={3} direction="column" sx={{ width: "33%" }} >
            <FormControl sx={{ width: "100%", paddingBottom: "25px" }}>
              <InputLabel id="visit-label">Visit Type</InputLabel>
              <CssSelect
                labelId="visit-label"
                id="visit"
                defaultValue=""
                value={visit}
                label="Visit Type"
                fullWidth
                onChange={handleSetVisit}
                MenuProps={{ disableScrollLock: true }}
                // onClick={() => {
                //   if (visit === "") {
                //     setvisitError(true);
                //   } 
                // }}
              >
                <MenuItem value='walk-in'>Walk In</MenuItem>
                <MenuItem value='appointment'>Appointment</MenuItem>
              </CssSelect>
            </FormControl>
            {(() => {
              if (visitErrorState) {
                return <FormHelperText style={{ color: "red", marginTop: -20, paddingBottom: "10px" }}>Visit Type Required</FormHelperText>
              } else {
                return <></>
              }
            })()}
          </Stack>
          <Stack spacing={3} direction="column" sx={{ width: "33%" }} >
            <FormControl sx={{ width: "100%", paddingBottom: "25px" }}>
              <InputLabel id="homeless-label">Housing Status</InputLabel>
              <CssSelect
                labelId="homeless-label"
                id="homeless"
                defaultValue=""
                value={homeless}
                label="Housing Status"
                fullWidth
                onChange={handleSetHomeless}
                MenuProps={{ disableScrollLock: true }}
                // onClick={() => {
                //   if (homeless === "") {
                //     setHomelessError(true);
                //   }
                // }}
              >
                <MenuItem value='homeless'>Unhoused</MenuItem>
                <MenuItem value='not-homeless'>Not Unhoused</MenuItem>
              </CssSelect>
            </FormControl>
            {(() => {
              if (homelessErrorState) {
                return <FormHelperText style={{ color: "red", marginTop: -20, paddingBottom: "10px" }}>Unhoused Status required</FormHelperText>
              } else {
                return <></>
              }
            })()}
          </Stack>
          </Stack>
          <Stack spacing={3} direction="row" >
          {/* <FormControl sx={{ width: "75%", paddingBottom: "25px" }}>

          </FormControl> */}
          <FormControl sx={{ width: "75%", paddingBottom: "25px" }}>
                                <InputLabel id="bottom-color-label">Item(s) Received</InputLabel>
                                <Select
                                    labelId="bottom-color-label"
                                    id="bottom-color"
                                    maximumSelectionLimit={1}
                                    multiple
                                    defaultValue=''
                                    value={items}
                                    onChange={handleSetItems}
                                    input={<OutlinedInput id="bottom-color-preference" label="Uniform Bottom Color(s)" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {itemsOptions.map((itemsOptions) => (
                                        <MenuItem
                                            key={itemsOptions}
                                            value={itemsOptions}
                                            style={getItems(itemsOptions, items, theme)}
                                        >
                                            {itemsOptions}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {(() => {
                                    if (itemsErrorState) {
                                        return <FormHelperText style={{ color: "red", paddingBottom: "25px" }}>Required</FormHelperText>
                                    } else {
                                        return <></>
                                    }
                                })()}
                            </FormControl>
            {/* <FormControl sx={{ width: "50%", paddingBottom: "25px" }}> */}


            {/* </FormControl>
                <InputLabel id="bottom-color-label">Item(s) Received</InputLabel> */}
            <FormControl fullWidth>

        <CssTextField
          label="Number of family members"
          type="number"
          variant='outlined'
          style={{ width: "100%", paddingBottom: "25px" }}
          value={numFamilyDemographics}
          onChange={handleNumFamilyDemographicssChange}
          InputProps={{ inputProps: { min: 1 } }}
        />
        {familyDemographics.map((member, index) => (
        <Box key={index}>
         <Typography variant='p' fontWeight={400} style={{ marginBottom: '10px', textAlign: "left" }}>
         Person {index + 1}
        </Typography>
        <Stack spacing={3} direction="row" >
          <FormControl fullWidth>
            
            <InputLabel>Gender</InputLabel>
            <Select
              value={member.gender}
              label="Gender"
              onChange={(e) => handleFamilyDemographicsChange(index, 'gender', e.target.value)}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            
            <InputLabel>Ethnicity</InputLabel>
            <Select
              value={member.ethnicity}
              label="ethnicity"
              onChange={(e) => handleFamilyDemographicsChange(index, 'ethnicity', e.target.value)}
            >
                <MenuItem value='American Indian or Alaska Native'>American Indian or Alaska Native</MenuItem>
                <MenuItem value='Asian'>Asian</MenuItem>
                <MenuItem value='Black or African American'>Black or African American</MenuItem>
                <MenuItem value='Hispanic or Latino'>Hispanic or Latino</MenuItem>
                <MenuItem value='Multi-Racial'>Multi-Racial</MenuItem>
                <MenuItem value='Native Hawaiian or Other Pacific Islander'>Native Hawaiian or Other Pacific Islander</MenuItem>
                <MenuItem value='White'>White</MenuItem>
                <MenuItem value='NA'>Prefer Not to Answer</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <CssTextField
              label="Age"
              type="number"
              value={member.age}
              variant='outlined'
              style={{ width: "100%", paddingBottom: "25px" }}
              onChange={(e) => handleFamilyDemographicsChange(index, 'age', e.target.value)}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </FormControl>
          </Stack>
        </Box>
      ))}
        </FormControl>
                            </Stack>
            <div
            style={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              }}>
            <SubmitButton
            variant='contained'
            onClick={handleSubmit} />
            </div>
            

      </form>
      </div>
    )
};


export default BuildCenterPackage;